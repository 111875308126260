<template>
  <div class="main_content">
    <searchEngines
      :platform="2"
      @change="search"
      @nameChange="nameChange"
    ></searchEngines>
    <section v-loading="loading">
      <h1 class="display_flex align_item_center" style="padding-left: 17px">
        <el-checkbox
          v-model="checkAll"
          @change="checkAllChange"
          :indeterminate="isIndeterminate"
          style="margin-right: 20px"
          >全选</el-checkbox
        >
        <el-popover
          popper-class="setClassifyPopover"
          placement="bottom"
          v-model="visible"
          trigger="manual"
        >
          <el-checkbox-group v-model="selectedClassifies">
            <el-checkbox
              v-for="(item, index) in allClassify"
              :key="index"
              :label="item.id"
              >{{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
          <div class="btns">
            <el-button @click="visible = false">取消</el-button>
            <el-button type="primary" @click="setClassifyHandle"
              >确定</el-button
            >
          </div>
          <el-button
            type="primary"
            icon="el-icon-folder-add"
            @click="setClassify()"
            slot="reference"
            >批量设置分类</el-button
          >
        </el-popover>
      </h1>
      <section style="margin-top: 20px">
        <el-card>
          <div slot="header" class="clearfix">
            <span>Tiktok搜索结果（{{ total }}）</span>
            <el-radio-group v-model="postdata.column" @change="sortChange">
              <el-radio-button label="followers">总粉丝数</el-radio-button>
              <el-radio-button label="heartCount">总点赞数</el-radio-button>
              <el-radio-button label="publish_count">总作品数</el-radio-button>
            </el-radio-group>
          </div>
          <div>
            <el-empty
              v-if="!loading && total == 0"
              description="暂无数据"
            ></el-empty>
            <listComponent
              :checkAll="checkAll"
              :allClassify="allClassify"
              @getUids="getUid"
              :platform="2"
              :list="tableList"
              @init="getList(firstPage)"
            ></listComponent>
            <pagination-vue
              @sizeChange="sizeInit"
              @currentChange="getList"
              :pageSize="postdata.page_num"
              :total="total"
              :firstPage="firstPage"
            />
          </div>
        </el-card>
      </section>
    </section>
  </div>
</template>
<script>
import paginationVue from "@/components/paginationVue.vue";
import searchEngines from "@/views/searchManage/childComponents/searchEngines.vue";
import listComponent from "@/views/searchManage/childComponents/listComponent.vue";
export default {
  components: {
    searchEngines,
    listComponent,
    paginationVue,
  },
  data() {
    return {
      checkAll: false,
      tableList: [],
      sortBy: "",
      postdata: {
        name: "",
        country: [],
        followers: [],
        category: [],
        page: 1,
        page_num: 10,
        column: "followers",
      },
      loading: false,
      total: 0,
      firstPage: 1,
      visible: false,
      selectedClassifies: [],
      allClassify: [],
      isIndeterminate: false,
      uid: [],
    };
  },
  created() {
    this.getAllCates();
    this.getList(1);
  },
  methods: {
    getUid(v) {
      this.uid = v;
      if (v.length === this.tableList.length || v.length === 0) {
        this.isIndeterminate = false;
      } else {
        this.isIndeterminate = true;
      }
    },
    checkAllChange() {
      console.log(this.checkAll);
      this.isIndeterminate = false;
    },
    setClassify() {
      if (this.uid.length < 1) {
        this.visible = false;
        this.selectedClassifies = [];
        this.$message({
          message: "至少选择一个网红",
          type: "warning",
        });
      } else {
        this.visible = true;
      }
    },
    getAllCates() {
      this.axios("get", `${this.$baseUrl}v1/TiktokUser/getClassifyList`, {
        page: 1,
        page_num: 1000,
      }).then((res) => {
        if (res.code == 1) {
          this.allClassify = res.data.data;
        }
      });
    },
    setClassifyHandle() {
      if (this.selectedClassifies.length < 1) {
        this.$message({
          message: "请选择分类",
          type: "warning",
        });
        return;
      }
      this.axios("POST", `${this.$baseUrl}v1/TiktokUser/writeUserClassify`, {
        cid: this.selectedClassifies,
        uid: this.uid,
      }).then((res) => {
        if (res.code == 1) {
          this.visible = false;
          this.uid = [];
          this.selectedClassifies = [];
          this.getList(this.firstPage);
          this.$forceUpdate();
        }
      });
    },
    sortChange() {
      this.getList(1);
    },
    nameChange(v) {
      this.postdata.name = v;
      this.getList(1);
    },
    search(obj) {
      this.postdata.country = obj.country;
      this.postdata.category = obj.category;
      this.postdata.followers = obj.followers;
      this.postdata.contact = obj.contact;
      this.postdata.website = obj.website;
      this.postdata.account = obj.account;
      this.postdata.commerce = obj.commerce;
      this.getList(1);
    },
    sizeInit(size) {
      this.postdata.page_num = size || 10;
      this.getList(1);
    },
    getList(page) {
      this.loading = true;
      this.postdata.page = page || this.postdata.page;
      this.axios(
        "get",
        `${this.$baseUrl}v1/TiktokUser/lists`,
        this.postdata
      ).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.tableList = res.data.data.map(item => {
            item.visible = false;
            return item;
          });
          this.total = res.data.total;
          this.firstPage = res.data.current_page;
        }
      });
    },
  },
};
</script>